export const STORE_ID = 'STORE_ID'
export const SERVICE_SELECT = 'SERVICE_SELECT'
export const SERVICE_LIST = 'SERVICE_LIST'
export const PROFESSIONAL_SELECT = 'PROFESSIONAL_SELECT'
export const PROFESSIONAL_LIST = 'PROFESSIONAL_LIST'
export const SLOT_SELECT = 'SLOT_SELECT'
export const DATE_SELECT = 'DATE_SELECT'
export const SLOT_LIST = 'SLOT_LIST'
export const STEP_SELECT = 'STEP_SELECT'
export const STEP_LIST = 'STEP_LIST'
export const QUESTION_LIST = 'QUESTION_LIST'
export const BOOKING_STATUS = 'BOOKING_STATUS'
export const PROFILE_INFO = 'PROFILE_INFO'
export const LOGIN_RETURN = 'LOGIN_RETURN'